import React from 'react'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box, Tooltip, IconButton, FormHelperText } from '@mui/material'
import {
  AddAPhoto as AddAPhotoIcon,
  HighlightOff as HighlightOffIcon,
  Videocam as VideocamIcon
} from '@mui/icons-material'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

import { BaseTextField, FilePreview } from 'components'

function FileUploadTextField({
  onFileChange,
  file,
  spacing = false,
  loading = false,
  allowDelete = false,
  onDelete = () => {},
  name = 'FileUploadTextField',
  allowIpCameras = false,
  onIpCameraPress = () => {},
  tooltip = 'takePhoto',
  fileError,
  ...props
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        my={1}
      >
        <>
          <BaseTextField name={name} loading={loading} {...props} />

          <input
            id={name}
            type='file'
            accept='capture=camera,image/*'
            onChange={onFileChange}
            style={{ display: 'none' }}
          />

          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            px={2}
          >
            <label style={{ flex: 1 }} htmlFor={name}>
              <Tooltip title={t(tooltip)}>
                <AddAPhotoIcon sx={{ mr: 2 }} />
              </Tooltip>
            </label>

            {allowIpCameras && (
              <Tooltip title={t('getPhoto')}>
                <IconButton onClick={onIpCameraPress} size='large'>
                  <VideocamIcon sx={{ color: theme.palette.common.black }} />
                </IconButton>
              </Tooltip>
            )}

            {allowDelete && (
              <Tooltip title={t('delete')}>
                <IconButton onClick={onDelete} size='large'>
                  <HighlightOffIcon
                    sx={{ color: theme.palette.common.black }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </>
      </Box>
      {file && !loading && <FilePreview value={file} spacing={spacing} />}
      {loading && <Skeleton height={150} />}
      {fileError ? (
        <FormHelperText error={true} variant='outlined' margin={undefined}>
          {fileError}
        </FormHelperText>
      ) : null}
    </>
  )
}

FileUploadTextField.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  file: PropTypes.string,
  spacing: PropTypes.bool,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  allowIpCameras: PropTypes.bool,
  onIpCameraPress: PropTypes.func,
  tooltip: PropTypes.string,
  fileError: PropTypes.string,
  props: PropTypes.object
}

export default FileUploadTextField
